import {
  CustomerDebt,
  CustomerDebtGroup,
  CustomerDebtHistory,
} from "./../../components/debts/customer-debt";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiConfig } from "../../../api.config";
import { Pagination } from "../model/constants";
import { PaginatedList } from "src/app/shared/models/base.model";
import { GivingDebtClientFilter } from "src/app/components/balans/balance";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  dataProduct: any = ([] = []); // Результат поиска в product.component
  allDataProductCount: number = 0;
  page: number = Pagination.DEFAULT_PAGE;
  size: number = Pagination.DEFAULT_PAGE_SIZE;

  productsInBasket: any = []; // Добавленные в корзину

  constructor(private http: HttpClient) {}

  getAllDictionary(type: string): Observable<any> {
    return this.http.get(`${ApiConfig.api}/dictionary/allDicts?type=${type}`);
  }

  getAllDictionaryByReferenceId(id: number, type: string): Observable<any> {
    return this.http.get(
      `${ApiConfig.api}/dictionary/allDictsByReference/${id}?type=${type}`
    );
  }

  addDictionary(type: string, body: any): Observable<any> {
    return this.http.post(`${ApiConfig.api}/dictionary?type=${type}`, body);
  }

  editDictionary(type: string, body: any): Observable<any> {
    return this.http.put(`${ApiConfig.api}/dictionary?type=${type}`, body);
  }

  deleteDic(id: number, type: string): Observable<any> {
    return this.http.delete(`${ApiConfig.api}/dictionary/${id}?type=${type}`);
  }

  addProduct(body: any): Observable<any> {
    return this.http.post(`${ApiConfig.api}/product`, body);
  }

  moveProducts(body: number[], storeId: number): Observable<any> {
    return this.http.put(
      `${ApiConfig.api}/store/moveProducts/${storeId}`,
      body
    );
  }

  startTransferProducts(productId: number, storeId: number): Observable<any> {
    return this.http.put(
      `${ApiConfig.api}/product-transfer-task/start-transfer-product/${productId}/${storeId}`,
      null
    );
  }

  acceptTransferProducts(transferId: number): Observable<any> {
    return this.http.put(
      `${ApiConfig.api}/product-transfer-task/accept-transfer-product/${transferId}`,
      null
    );
  }

  rejectTransferProducts(transferId: number): Observable<any> {
    return this.http.put(
      `${ApiConfig.api}/product-transfer-task/reject-transfer-product/${transferId}`,
      null
    );
  }

  cancelTransferProducts(transferId: number): Observable<any> {
    return this.http.put(
      `${ApiConfig.api}/product-transfer-task/cancel-transfer-product/${transferId}`,
      null
    );
  }

  deleteProduct(id: number, changeDuty: boolean): Observable<any> {
    return this.http.delete(
      `${ApiConfig.api}/product/${id}?changeDuty=${changeDuty}`
    );
  }

  filterDetailProduct(body: any, page: number, size: number): Observable<any> {
    return this.http.post(
      `${ApiConfig.api}/product/filter-detail?page=${page}&size=${size}&sort=id,desc`,
      body
    );
  }

  filterTransfersProduct(
    body: any,
    page: number,
    size: number
  ): Observable<any> {
    return this.http.post(
      `${ApiConfig.api}/product-transfer-task/filter?page=${page}&size=${size}&sort=id,desc`,
      body
    );
  }

  filterDetailXls(body: any): Observable<any> {
    return this.http.post(`${ApiConfig.api}/product/filter-detail-xls`, body, {
      responseType: "blob",
    });
  }

  filterProduct(body: any, page: number, size: number): Observable<any> {
    return this.http.post(
      `${ApiConfig.api}/product/filter?page=${page}&size=${size}&sort=id,desc`,
      body
    );
  }

  filterProductByImei(imei: string): Observable<any> {
    return this.http.get(`${ApiConfig.api}/product/find-by-imei/${imei}`);
  }

  getCurrentCource(id: number): Observable<any> {
    return this.http.get(`${ApiConfig.api}/currency-course/actual/${id}`);
  }

  saveCurrencyCoucse(body: any): Observable<any> {
    return this.http.post(`${ApiConfig.api}/currency-course`, body);
  }

  sale(body: any): Observable<any> {
    return this.http.post(`${ApiConfig.api}/sale/sale`, body);
  }

  return(body: any, changeDuty: boolean): Observable<any> {
    return this.http.post(
      `${ApiConfig.api}/sale/return?changeDuty=${changeDuty}`,
      body
    );
  }

  situationBusiness(params: any = {}): Observable<any> {
    return this.http.post(`${ApiConfig.api}/report/situation-business`, params);
  }

  reportSale(body: any, page: number, size: number): Observable<any> {
    return this.http.post(
      `${ApiConfig.api}/report/sales/data?page=${page}&size=${size}&sort=saleDate,desc`,
      body
    );
  }

  xlsxSale(body: any): Observable<any> {
    return this.http.post(`${ApiConfig.api}/report/sales/xlsx`, body, {
      responseType: "blob",
    });
  }

  warranty(body: any): Observable<any> {
    return this.http.post(`${ApiConfig.api}/report/warranty/pdf`, body, {
      responseType: "blob",
    });
  }

  saleFilter(body: any, page: number, size: number): Observable<any> {
    return this.http.post(
      `${ApiConfig.api}/sale/filter?page=${page}&size=${size}&sort=id,desc`,
      body
    );
  }

  getSaleByOrder(orderNum: Number): Observable<any> {
    return this.http.get(`${ApiConfig.api}/sale/${orderNum}`);
  }

  productPosting(body: any, page: number, size: number): Observable<any> {
    return this.http.post(
      `${ApiConfig.api}/product-posting/filter?page=${page}&size=${size}&sort=createdDate,desc`,
      body
    );
  }

  correctionPrice(body: any): Observable<any> {
    return this.http.put(
      `${ApiConfig.api}/product-posting/update-buy-price`,
      body
    );
  }

  debtsToProviders(body: any, page: number, size: number): Observable<any> {
    return this.http.post(
      `${ApiConfig.api}/duty/store-provider?page=${page}&size=${size}&sort=totalDutyTenge,desc`,
      body
    );
  }

  cancelRepaymentProvider(
    storeId: number,
    storeProviderDutyHistoryId: number
  ): Observable<any> {
    return this.http.put(
      `${ApiConfig.api}/duty/cancel-repayment-provider/${storeId}/${storeProviderDutyHistoryId}`,
      null
    );
  }

  cancelRepaymentClient(
    storeId: number,
    clientDutyHistoryId: number
  ): Observable<any> {
    return this.http.put(
      `${ApiConfig.api}/duty/cancel-repayment-client/${storeId}/${clientDutyHistoryId}`,
      null
    );
  }

  cancelRepaymentStore(
    storeFirstId: number,
    storeDutyHistoryId: number
  ): Observable<any> {
    return this.http.put(
      `${ApiConfig.api}/duty/cancel-repayment-store/${storeFirstId}/${storeDutyHistoryId}`,
      null
    );
  }

  getClientsDebts(
    body: any,
    page: number,
    size: number
  ): Observable<PaginatedList<CustomerDebt>> {
    return this.http.post<PaginatedList<CustomerDebt>>(
      `${ApiConfig.api}/duty/client-store?page=${page}&size=${size}&sort=totalDuty,desc`,
      body
    );
  }

  getStoresDebts(body: any, page: number, size: number): Observable<any> {
    return this.http.post(
      `${ApiConfig.api}/duty/store-store?page=${page}&size=${size}&sort=totalDuty,desc`,
      body
    );
  }

  revisionProduct(body: any): Observable<any> {
    return this.http.post(`${ApiConfig.api}/revision/product`, body);
  }

  revisionBalance(body: any): Observable<any> {
    return this.http.post(`${ApiConfig.api}/revision/balance`, body);
  }

  debtsProvidersHistory(
    body: any,
    page: number,
    size: number
  ): Observable<any> {
    return this.http.post(
      `${ApiConfig.api}/duty/store-provider-history?page=${page}&size=${size}&sort=id,desc`,
      body
    );
  }

  debtsClientHistory(
    body: any,
    page: number,
    size: number
  ): Observable<PaginatedList<CustomerDebtHistory>> {
    return this.http.post<PaginatedList<CustomerDebtHistory>>(
      `${ApiConfig.api}/duty/client-store-history?page=${page}&size=${size}&sort=createdDate,desc`,
      body
    );
  }

  debtsStoreHistory(body: any, page: number, size: number): Observable<any> {
    return this.http.post(
      `${ApiConfig.api}/duty/store-store-history?page=${page}&size=${size}&sort=createdDate,desc`,
      body
    );
  }

  repaymentProvider(
    storeId: number,
    providerId: number,
    currencyCode: string,
    dutyAmount: number,
    description: string
  ): Observable<any> {
    return this.http.put(
      `${ApiConfig.api}/duty/repayment-with-provider/${storeId}/${providerId}/${currencyCode}/${dutyAmount}?description=${description}`,
      null
    );
  }

  repaymentProviderFromBalance(
    storeId: number,
    providerId: number,
    currencyCode: string,
    dutyAmount: number,
    description: string
  ): Observable<any> {
    return this.http.put(
      `${ApiConfig.api}/duty/repayment-with-provider-from-balance/${storeId}/${providerId}/${currencyCode}/${dutyAmount}?description=${description}`,
      null
    );
  }

  repaymentClient(
    data: GivingDebtClientFilter,
    isCashDuty: boolean
  ): Observable<any> {
    const { storeId, clientId, amount, description, dutyReturnDate } = data;
    let params = new HttpParams();

    params = params.append("dutyReturnDate", dutyReturnDate);

    params = params.append("isCashDuty", isCashDuty);
    
    if (description) {
      params = params.append("description", description);
    }

    return this.http.put(
      `${ApiConfig.api}/duty/repayment-client-with-store/${storeId}/${clientId}/${amount}`,
      null,
      {
        params,
      }
    );
  }

  repaymentStore(
    storeFirstId: number,
    storeSecondId: number,
    dutyAmount: number,
    description: string
  ): Observable<any> {
    return this.http.put(
      `${ApiConfig.api}/duty/repayment-store-with-store/${storeFirstId}/${storeSecondId}/${dutyAmount}?description=${description}`,
      null
    );
  }
}
