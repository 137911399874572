export const INVALID: string = "INVALID";

/**
 * DICTIONARY
 */
export const PRODUCT_TYPE: string = "PRODUCT_TYPE"; // тип продукта
export const FAMILY: string = "FAMILY"; // семейство
export const MODEL: string = "MODEL"; // модель
export const MEMORY: string = "MEMORY"; // память
export const COLOR: string = "COLOR"; // цвет
export const CURRENCY: string = "CURRENCY"; // валюта
export const RAM: string = "RAM"; // ОЗУ

/**
 * PERSON RESOURCE
 */
export const USER: string = "USER"; // Продавец
export const CLIENT: string = "CLIENT"; // Клиет
export const PROVIDER: string = "PROVIDER"; // Поставщик

export const YES = "YES"; // есть в наличий
export const SOLD = "SOLD"; // продано
export const BRON = "BRON"; // бронь
export const NEW = "NEW"; // новая
export const OLD = "OLD"; // б/у

export const SEND = "SEND"; // Перевод на общую кассу
export const WITHDRAW = "WITHDRAW"; // Снять кассу
export const DEBTSTORE = "DEBTSTORE"; // В долг другим магазинам
export const DEBTCLIENT = "DEBTCLIENT"; // В долг клиентам
export const ADDCASH = "ADDCASH"; // Добавить баланс в магазин

/**
 * режимы формы
 */
export const enum FormMode {
  View = 0,
  Edit = 1,
  New = 2,
  Empty = 3,
}

export class Pagination {
  public static readonly YES: number = 1;
  public static readonly NO: number = 0;
  public static readonly DEFAULT_PAGE_SIZE: number = 50;
  public static readonly DEFAULT_PAGE: number = 0;
}

export const ADD_PRODUCT: string = "add_product"; // admin/warehouse/add-product
export const WAREHOUSE_SEARCH: string = "search_product"; // admin/warehouse/warehouse-search
export const SALE: string = "sale_product"; // admin/sale
export const PRODUCT: string = "product"; // admin/product
export const INTERCEPTOR: string = "tokenInterceptor";

/**
 * ROlES
 */

export const SUPER_ADMIN = "SUPER_ADMIN";
export const ADMIN = "ADMIN";
export const SELLER = "SELLER";

export const enum FlowType {
  POSTING = "POSTING",
  SALE = "SALE",
  RETURN = "RETURN",
  DELETE = "DELETE",
  PRICE_CHANGE = "PRICE_CHANGE",
  COLOR_CHANGE = "COLOR_CHANGE",
  MEMORY_CHANGE = "MEMORY_CHANGE",
}
